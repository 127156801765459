var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"qr-code-container"},[_c('QRCode',{attrs:{"text":_vm.text}}),(_vm.imageSettings.src)?_c('img',{staticClass:"qr-logo",style:({
			width: _vm.imageSettings.width + 'px',
			height: _vm.imageSettings.height + 'px',
			position: 'absolute',
			top: '50%',
			left: '52%',
			transform: 'translate(-50%, -50%)',
			background: '#fff',
			zIndex: 1,
		}),attrs:{"src":_vm.imageSettings.src}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }
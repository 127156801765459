<template>
	<div class="main">
		<div v-if="isOnThankyou">
			<h1 class="h1" style="margin: 0">{{ $t('sessionParking.sessionResult.title') }}</h1>
			<!-- <p style="margin: 0; text-align: center">
				{{ $t('sessionParking.sessionResult.infoText') }} <br style="text-align: center" />
				{{ $t('sessionParking.sessionResult.infoText2') }}
			</p> -->
		</div>

		<hr />

		<hr />
		<div class="votre-container" v-if="sessionDetail">
			<div style="display: flex; align-items: center">
				<img
					src="../../assets//images/session/icon_location_bigparking.svg"
					alt="Add Circle Icon"
					class="icon-svg"
					style="margin-top: 10px"
				/>
				<h3 class="h1" style="margin-left: 10px; margin-bottom: 4px; color: #278ac9">
					{{ sessionDetail.parking && sessionDetail.parking.code }} -
					{{ sessionDetail.parking && sessionDetail.parking.name }}
				</h3>
			</div>

			<p class="parking-address">
				<img
					v-if="
						sessionDetail.parking &&
						sessionDetail.parking.legacyAddress[activeLanguage.value].includes('(M)')
					"
					class="how-it-works-address-wrapper-m"
					src="../../assets/images/session/Métrro.svg"
				/>
				{{
					sessionDetail.parking &&
					replaceSubstring(sessionDetail.parking.legacyAddress[activeLanguage.value])
				}}
			</p>
			<router-link
				class="detail-link"
				style="margin-left: 2.1rem"
				:to="{ path: '/parkings', query: { parkingId: sessionDetail.parking.id } }"
				>{{ $t('sessionParking.sessionResult.mapCtaText') }}</router-link
			>

			<table class="details">
				<tr>
					<td class="col2">{{ $t('sessionParking.sessionResult.sessionId') }}</td>
					<td class="spacer"></td>
					<td class="col2">{{ $t('sessionParking.sessionResult.Date') }}</td>
				</tr>
				<tr class="row-values">
					<td class="col">{{ sessionDetail.sessionId }}</td>
					<td class="spacer"></td>
					<td class="col">{{ date(sessionDetail.createdAt, { dateSeparator: '. ' }) }}</td>
				</tr>

				<tr>
					<td class="col2">{{ $t('sessionParking.sessionResult.StartedAt') }}</td>
					<td class="spacer"></td>
					<td class="col2">{{ $t('sessionParking.sessionResult.endedAt') }}</td>
				</tr>
				<tr>
					<td class="col">{{ time(sessionDetail.startTime) || '-' }}</td>
					<td class="spacer"></td>
					<td class="col">{{ time(sessionDetail.endTime) || '-' }}</td>
				</tr>

				<tr>
					<td class="col2">{{ $t('sessionParking.sessionResult.duration') }}</td>
					<td class="spacer"></td>
					<div style="display: flex">
						<td class="col2">{{ $t('sessionParking.sessionResult.total') }}</td>
						<!-- <img
							src="../../assets//images/session/icon_info_small.svg"
							alt="Add Circle Icon"
							class="icon-svg"
						/> -->
					</div>
				</tr>
				<tr>
					<td class="col">
						{{ diffInTime(sessionDetail.startTime, sessionDetail.endTime) || '-' }}
					</td>
					<td class="spacer"></td>
					<td class="col">€{{ sessionDetail.totalAmountPaid }}</td>
				</tr>

				<tr>
					<td class="col2">{{ $t('sessionParking.sessionResult.numberOfBicycles') }}</td>
					<td class="spacer"></td>
				</tr>
				<tr style="display: flex">
					<td class="col">
						{{ sessionDetail.bikes }}
						<img
							src="../../assets//images/session/cycle.svg"
							alt="Add Circle Icon"
							class="icon-svg"
							style="vertical-align: bottom; padding-left: 5px; width: 31px"
						/>
					</td>
				</tr>
			</table>
		</div>

		<router-link
			class="detail-link"
			style="margin-top: 24px; text-align: center; font-size: 16px"
			to="/profile#session-history"
			>{{ $t('sessionParking.sessionResult.ctaText') }}</router-link
		>
	</div>
</template>

<script>
import { mapGetters, mapActions, mapMutations, mapState } from 'vuex'
import { date, time, diffInTime } from '@/helpers'

export default {
	name: 'seeyousoon',
	data() {
		return {
			sessionDetail: null,
			isOnThankyou: this.$route.fullPath.includes('thankyou'),
		}
	},
	computed: {
		...mapState({
			activeLanguage: (state) => state.language,
		}),
	},
	methods: {
		...mapActions('session', {
			getUserSessionDetail: 'getUserSessionDetail',
		}),
		replaceSubstring(address) {
			if (address) {
				return address.replace('(M)', '')
			}
			return ''
		},
		date,
		time,
		diffInTime,
		handleClick() {
			this.$emit('next-step')
		},
	},
	mounted() {
		this.getUserSessionDetail(parseInt(this.$route.params.sessionId)).then((sessionDetail) => {
			this.sessionDetail = sessionDetail
		})
	},
}
</script>

<style lang="scss" scoped>
@import '../../assets/scss/pages/session/index';

.left-arrow::before {
	content: '';
	display: inline-block;
	width: 0;
	height: 0;
	border-top: 5px solid transparent;
	border-bottom: 5px solid transparent;
	border-right: 5px solid black;
	margin-right: 5px;
}

.material-symbols-outlined {
	font-family: 'YourMaterialFontFamily';
	font-variation-settings: 'FILL' 0, 'wght' 400, 'GRAD' 0, 'opsz' 24;
}

.main {
	display: flex;
	flex-direction: column;
	/* border: 0.5px solid rgb(224, 215, 215); */
	padding: 24px;
	width: 25%;
	margin-left: auto;
	margin-right: auto;
	/* box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); */
	font-family: Poppins regular;

	@media (max-width: 768px) {
		width: 20.4378rem;
	}
}

.h1 {
	color: #1b388a;
	text-align: center;
	font-weight: bold;
	font-family: Poppins Bold;
}
.detail-link {
	color: red;
	font-size: 12px;
	font-family: Poppins Bold;
	margin-top: 4px;
}

.votre-container {
	background-color: #fff;
	padding: 10px;
	color: #3c3c3b;
	// align-items: center;
	// text-align: center;
	.parking-address {
		font-family: Poppins Bold;
		font-size: 12px;
		margin: 0 0 0 2.1rem;
	}
	.details {
		margin-top: 12px;
		.row-values {
			padding-top: 0px;
		}
	}
}

.montant-container {
	background-color: #ffd023;
	height: 1.8rem;
	text-align: center;
	padding: 10px;
	align-items: center;
	justify-content: space-between;
}

button {
	padding: 10px 20px;
	background-color: #e75131;
	/* Green */
	border: none;
	color: white;
	text-align: center;
	text-decoration: none;
	display: inline-block;
	justify-content: center;
	font-size: 16px;
	margin: 4px 2px;
	width: 100%;
	cursor: pointer;
	border-radius: 5px;
	box-shadow: -5px 5px 0px 0px rgba(182, 175, 175, 0.5);
	/* Black shadow with x-offset -5px, y-offset 5px, and blur radius 0px */
}

.col {
	font-weight: bold;
	margin: 0%;
	padding-top: 0px;
}

.col2 {
	color: #6d6a6a9f;
	font-size: small;
	margin: 0%;
	padding-bottom: 4px;
}

table {
	border-collapse: collapse;
	width: 100%;
}

td {
	text-align: left;
	padding: 8px;
}

td:nth-child(2) {
	text-align: right;
}

.spacer {
	width: 10px;
}
</style>

<template>
	<div class="bg">
		<PreviousButton />
		<Loading v-if="ourParking.length < 1" />
		<div v-else="ourParking.length > 0" class="bg-how-it-works">
			<div class="bg-how-it-works-top-section">
				<div class="bg-how-it-works-top-section-first">
					<div class="bg-how-it-works-top-section-first-head">
						<h1 class="bg-how-it-works-top-section-first-head-title">{{ $t('howItWorks.title') }}</h1>
						<img v-if="APP_ENV_SESSION" src="../../assets/images/session/info_lightblue.svg"
							class="bg-how-it-works-top-section-first-head-img" />
							<div class="bg-how-it-works-coming-soon-button" v-if="!APP_ENV_SESSION">
								COMING SOON</div>
					</div>
					<p class="bg-how-it-works-detail">
						{{ $t('howItWorks.info') }}
					</p>
					<div v-if="APP_ENV_SESSION" class="bg-how-it-works-find-parking-button" @click="$router.push('/parkings?typeFilter=ParkingLaSession')">
						{{ $t('howItWorks.ctaTitle') }}
	</div>
				</div>
				<!-- First accordion -->
				<div v-for="(item, index) in accordionItems.slice(0, 1)" :key="'first-accordion-' + index"
					class="accordion-item first" :class="{ active: item.active }">
					<div class="accordion-header" @click="toggleAccordion(index)">
						{{ item.title }}
						<span class="arrow"></span>
					</div>
					<div class="accordion-content" v-show="item.active" v-for="(newItem, newIndex) in item.content">
						<div class="content-order-list">
							<div class="left">{{ newIndex + 1 }}</div>
							<div class="right">
								<div class="title">
									{{ newItem.title }}
								</div>
								<p class="description">
									{{ newItem.description }}
								</p>
							</div>
						</div>
					</div>
				</div>
			</div>
			<!-- New section -->
			<div class="bg-how-it-works-our-parking">
				<h2 class="bg-how-it-works-our-parking-title">{{ $t('howItWorks.parking.title') }}</h2>
				<div class="bg-how-it-works-our-parking-main">
					<div class="bg-how-it-works-our-parking-main-card" v-for="(item, index) in ourParking"
						:key="'first-parking-' + index">
						<div v-for="(image, index) in item.images"
							:key="`parking-image-${index}`" class="bg-how-it-works-our-parking-main-card-img-section">
							<img v-if="index === 0" class="bg-how-it-works-our-parking-main-card-img-section-img"
								:src="image" />
						</div>
						<div class="bg-how-it-works-our-parking-main-card-desc">
							<img class="bg-how-it-works-our-parking-main-card-desc-img"
								src="../../assets/images/icon_location_bigparking.svg" />
							<div class="bg-how-it-works-our-parking-main-card-desc-section-two">
								<h4 class="bg-how-it-works-our-parking-main-card-desc-section-two-title">
									{{ item.name }}
								</h4>
								<div class="how-it-works-address-wrapper">
				  			<img v-if="item.address[activeLanguage.value] && item.address[activeLanguage.value].includes('(M)')" class="how-it-works-address-wrapper-m" src='../../assets/images/session/Métrro.svg' />
								<h5 clas="bg-how-it-works-our-parking-main-card-desc-section-two-sub-title"
										v-html="replaceSubstring(item.address[activeLanguage.value])">
								</h5>
								</div>
								<div class="bg-how-it-works-our-parking-main-card-desc-section-two-info">
									<div class="bg-how-it-works-our-parking-main-card-desc-section-two-info-left">
										<div
											class="bg-how-it-works-our-parking-main-card-desc-section-two-info-left-text">
											<img src="../../assets/images/euro.svg" width="20" height="20" />
											<div class="">
												<p class="">
													0h {{ $t('general.to') }} {{ item.sessionConfig.subscriptionFreeHours }}h : {{ $t('general.free') }}
												</p>
												<p class="">
													{{ item.sessionConfig.subscriptionFreeHours }}h {{ $t('general.to') }} 24h :
													{{ item.sessionConfig.perHourCost }}€
												</p>
											</div>
										</div>
										<div @click="$router.push('/parkings?parkingId='+item.id+'')" class="bg-how-it-works-our-parking-main-card-desc-section-two-info-left-action">{{ $t('howItWorks.parking.ctaText') }}</div>
									</div>
									<div class="bg-how-it-works-our-parking-main-card-desc-section-two-info-right"">
										<img src="../../assets/images/bycicle.svg" width="20" height="20" />
									<p class="bg-how-it-works-our-parking-main-card-desc-section-two-info-right-text">
										{{ $t('general.regular') }} : {{ item.capacityClassic }}
									</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<!-- Remaining accordions -->
		<div v-for="(item, index) in accordionItems.slice(1)" :key="'remaining-accordion-' + index"
			class="accordion-item" :class="{ active: item.active }">
			<div class="accordion-header" @click="toggleAccordion(index + 1)">
				{{ item.title }}
				<span class="arrow"></span>
			</div>
			<div class="accordion-content" v-show="item.active">
				<div v-if="index === accordionItems.slice(1).length - 1">
					<!-- Show last accordion content -->
					<div v-for="(newItem, newIndex) in item.content" :key="'last-accordion-' + newIndex">
						<div class="content-order-list" v-if="newItem.description">
							<div class="left">{{ newIndex + 1 }}</div>
							<div class="right">
								<div class="title">{{ newItem.title }}</div>
								<p class="description">{{ newItem.description }}</p>
							</div>
						</div>
						<p class="message" v-html="newItem.message"></p>
					</div>
				</div>
				<div v-else>
					<!-- Render other accordion contents -->
					<div v-if="Array.isArray(item.content)">
						<!-- If content is an array, render each item individually -->
						<div v-for="(contentItem, contentIndex) in item.content"
							:key="'accordion-content-' + contentIndex">
							<p class="message">{{ contentItem }}</p>
						</div>
					</div>
					<div v-else>
						<!-- If content is not an array, render it as is -->
						<p class="message">{{ item.content }}</p>
					</div>
				</div>
			</div>

		</div>
	</div>
	</div>
</template>

<script>
import PreviousButton from '@/components/PreviousButton.vue'
import { mapState, mapActions } from 'vuex'
import Loading from '@/components/Loading.vue'
import { APP_ENV_SESSION } from '@/config'

export default {
	name: 'HowItWorks',
	components: {
		PreviousButton,
		Loading,
	},
	data() {
		return {
			ourParking: [
				//     {
				//     image_url: '../../assets/images/parkings/NeighborhoodLocker.jpg',
				//     title: 'Parking Bourse*Beurs',
				//     address: '(M) BOURSE, 1000 BRUXELLES',
				//     tarif: '0h à 4h : gratuit',
				//     tarif_one: '4h à 24h : 1,25€',
				//     place: 'Classic : 304',
				//     link: '#'
				//     },
				//     {
				//     image_url: '../../assets/images/parkings/NeighborhoodLocker.jpg',
				//     title: 'GP02 - De Broukère',
				//     address: '(M) BOURSE, 1000 BRUXELLES',
				//     tarif: '0h à 4h : gratuit',
				//     tarif_one: '4h à 24h : 1,25€',
				//     place: 'Classic : 304',
				//     link: '#'
				//     }
			],
	  APP_ENV_SESSION,

			accordionItemsfr: [
				{
					title: 'Comment ça marche ?',
					content: [
						{
							title: 'Incrivez-vous sur la plateforme',
							description:
								'Rendez-vous sur le site cycloparking.brussels ou scannez le code QR à l’entrée du parking. Créez votre compte et enregistrez vos données bancaires.',
						},
						{
							title: 'Démarrer une session',
							description:
								'Scannez le code QR obtenu pour avoir accès au parking et déposez votre vélo. Utilisez le même QR code pour la sortie.',
						},
						{
							title: 'Récupérer son vélo',
							description:
								'Obtenez le QR code pour l’entrée et la sortie et terminez votre session de stationnement. Les 4 premières heures sont gratuites !',
						},
					],
					active: false,
				},
				{
					title: 'Pour qui ?',
					content:
						"Pour tout cycliste à la recherche d'un emplacement sécurisé pour une durée déterminé.",
					active: false,
				},
				{
					title: 'Avantages ?',
					content:
						"Mettre votre vélo en sécurité, à l'abri des intempéries, dans un parking facile d'accès, sans mauvaise surprise!  ",
					active: false,
				},
				{
					title: "10 tips de sécurité",
					content: [
						{
							description: "J’attache mon vélo par le cadre avec un antivol de qualité.",
						},
						{
							description: "Je m’identifie lors de chaque accès au moyen de mon badge, de ma MOBIB ou de mon application.",
						},
						{
							description: "Je ne laisse entrer personne en même temps que moi.",
						},
						{
							description: "Je ne gêne pas le stationnement, l’entrée et la sortie des autres vélos avec mes accessoires ou autres.",
						},
						{
							description: "J’utilise le parking pour garer mon vélo et non pas pour stocker du matériel.",
						},
						{
							description: "J’utilise uniquement l’espace qui m’est réservé.",
						},
						{
							description: "Je vérifie que le parking est correctement fermé et verrouillé en sortant.",
						},
						{
							description: "J’utilise le parking comme une personne prudente et raisonnable (pas de déchet, etc.).",
						},
						{
							description: "Je respecte le voisinage en fermant le parking doucement.",
						},
						{
							description: "Je signale tout incident à Cycloparking.",
						},
						{
							message: "Nous vous recommandons d’enregistrer votre vélo sur le site mybike.brussels, pour commander un  sticker et le placer sur votre vélo afin de l’identifier.</br> Pour toute question relative aux services offerts par Cycloparking, vous êtes invité à  consulter la rubrique FAQ du site web (ci-après « FAQ »). </br>Le service clientèle Cycloparking est joignable directement par mail à l’adresse cycloparking@parking.brussels ou par téléphone au 0800 35 678 (gratuit) du lundi au vendredi de 8h30 à 13het de 14h à 17h",
						},
					],
					active: false,
				},
			],
			accordionItemsnl: [
				{
					title: 'Hoe werkt het?',
					content: [
						{
							title: 'Registreer je op het platform',
							description:
								'Ga naar de website cycloparking.brussels of scan de QR-code aan de ingang van de parking.Maak je account aan en registreer je bankgegevens.',
						},
						{
							title: 'Start een sessie',
							description:
								'Scan de ontvangen QR-code om toegang te krijgen tot de parking en je fiets achter te laten. Gebruik dezelfde QR-code voor de uitgang.',
						},
						{
							title: 'Haal je fiets op',
							description:
								'Ontvang de QR-code voor het binnen- en buitengaan en beëindig je parkeersessie. De eerste 4 uur zijn gratis!',
						},
					],
					active: false,
				},
				{
					title: 'Voor wie?',
					content:
						"Voor alle fietsers die op zoek zijn naar een beveiligde plek voor bepaalde duur.",
					active: false,
				},
				{
					title: 'De voordelen?',
					content:
						"Stal je fiets veilig, beschermd tegen slecht weer, op een vlot toegankelijke parking, zonder onaangename verrassingen!",
					active: false,
				},
				{
					title: "10 veiligheidstips",
					content: [
						{
							description: "Ik beveilig mijn fiets aan het frame met een kwaliteitsslot.",
						},
						{
							description: "Ik identificeer mezelf bij elke toegang via mijn badge, mijn MOBIB of mijn app.",
						},
						{
							description: "Ik laat niemand anders mee binnen op hetzelfde moment als ik.",
						},
						{
							description: "Ik belemmer niet het parkeren van andere fietsen met mijn accessoires of andere dingen.",
						},
						{
							description: "Ik gebruik de parking om mijn fiets te parkeren en niet om mijn uitrusting op te bergen.",
						},
						{
							description: "Ik gebruik enkel de ruimte die voor mij gereserveerd is.",
						},
						{
							description: "Ik controleer of de parking goed is gesloten en vergrendeld als ik vertrek.",
						},
						{
							description: "Ik gebruik de parking als een voorzichtig en redelijk persoon (geen rommel, etc.).",
						},
						{
							description: "Ik respecteer de buurt door de parking zachtjes te sluiten.",
						},
						{
							description: "Ik breng Cycloparking op de hoogte van elk incident",
						},
						{
							message: "We raden u aan om uw fiets te registeren op de website mybike.brussels en een sticker te bestellen. Die kan u na ontvangst op uw fiets plakken om hem te identificeren.</br>Voor alle vragen over de diensten van Cycloparking, kan u terecht in de FAQ-rubriek op de website (hierna ‘FAQ’).</br>U kan contact opnemen met de klantendienst van Cycloparking door een e-mail te sturen naarcycloparking@parking.brussels of te telefoneren naar 0800 35 678 (gratis)van maandag tot en met vrijdag, van 8u30 tot 13u en van 14u tot 17u",
						},
					],
					active: false,
				},
			],
			accordionItemsen: [
				{
					title: 'How does it work?',
					content: [
						{
							title: 'Register on the platform',
							description:
								'Visit cycloparking.brussels or scan the QR code at the parking location entrance.Create your account and enter your bank details.',
						},
						{
							title: 'Start a session',
							description:
								'Scan the QR code to gain access to the parking location and drop off your bike. Use the same QR code to leave.',
						},
						{
							title: 'Pick up your bike',
							description:
								'Get the QR code to enter and leave, and complete your parking session. The first 4 hours are free! ',
						},
					],
					active: false,
				},
				{
					title: "Who's it for?",
					content:
						"For cyclists looking for a secure place to park for a given period of time.",
					active: false,
				},
				{
					title: 'What are the advantages?',
					content:
						"Keep your bike safe, protected from the elements, in an easily accessible parking location, with no unpleasant surprises!",
					active: false,
				},
				{
					title: "Ten safety tips",
					content: [
						{
							description: "I secure my bike by the frame using a good quality lock.",
						},
						{
							description: "I identify myself at each access with my badge, my MOBIB or my app.",
						},
						{
							description: "I do not let anyone in at the same time as me.",
						},
						{
							description: "I do not hinder the parking of other bikes with my accessories or other things.",
						},
						{
							description: "I use the parking space to park my bike and not to store equipment.",
						},
						{
							description: "I only use the rack and the space reserved for me.",
						},
						{
							description: "I check that the parking space is properly closed and locked when I leave.",
						},
						{
							description: "I use the parking space as a careful and reasonable person(no littering, etc.).",
						},
						{
							description: "I respect the neighbourhood by closing the parking space gently.",
						},
						{
							description: "I report any incident to Cycloparking",
						},
						{
							message: "We recommend registering your bike on the mybike.brussels website. You will then be able to order an identification sticker to put on your bike.</br>For any questions regarding the services provided by Cycloparking, please read the FAQ section of the website (hereafter ‘FAQ’).</br>Cycloparking customer service can be contacted directly by e-mail at cycloparking@parking.brussels or by phone on 0800 35 678 (free)from Monday to Friday, from 8:30 to 13:00 and from 14:00 to 17:00",
						},
					],
					active: false,
				},
			],
		}
	},
	computed: {
		...mapState({
			activeLanguage: (state) => state.language,
			allParkings: (state) => state.parkings.all,
			loading: (state) => state.loading,
		}),

		accordionItems() {
        switch (this.activeLanguage.value) {
            case 'fr':
                return this.accordionItemsfr;
            case 'nl':
                return this.accordionItemsnl;
            case 'en':
                return this.accordionItemsen;
            default:
                // Return a default value or handle the case when the language is not recognized
                return [];
        }
    }
	},
	mounted() {
		this.getParkingData()
	},
	methods: {
		...mapActions('parkings', ['getSessionParkings']),
		toggleAccordion(index) {
			this.$set(this.accordionItems, index, {
				...this.accordionItems[index],
				active: !this.accordionItems[index].active,
			})
		},
		replaceSubstring(address) {
        if (address) {
            return address.replace('(M)', '');
        }
        return '';
    },
		async getParkingData() {
			try {
				// Call the action to fetch parking data
				await this.getSessionParkings()
				this.ourParking = this.allParkings.filter((a) => a.sessionConfig !== null)
			} catch (error) {
				console.error('Error fetching parking data:', error)
			}
		},
	},
}
</script>

<style lang="scss" scoped>
@import '../../assets/scss/pages/session/index';

.accordion-item {
	margin-bottom: 10px;
	width: 50%;
}

.accordion-item:last-child {
	margin-bottom: 50px;
}

.accordion-header {
	cursor: pointer;
	padding: 16px;
	background-color: #faf2d5;
	display: flex;
	justify-content: space-between;
	align-items: center;
	border: 1px solid #faf2d5;
	border-radius: 4px;
	color: #3c3c3b;
	font-size: 18px;
	line-height: 27px;
	font-family: 'Poppins Bold';
}

.arrow {
	background-image: url('../../assets/images/arrow-down.png');
	background-repeat: no-repeat;
	background-position: right;
	background-size: contain;
	width: 15px;
	height: 15px;
}

.accordion-item.active .accordion-header .arrow {
	transform: rotate(180deg);
	transition: transform 0.3s ease;
	/* Optionally add transition for smooth animation */
}

.accordion-content {
	display: none;
	padding: 16px;
	background-color: #faf2d5;
}

.accordion-content .content-order-list {
	display: flex;
	gap: 10px;
	align-items: baseline;
}

.accordion-content .content-order-list .left {
	border: 3px solid #268ac9;
	border-radius: 100%;
	width: 20px;
	height: 20px;
	text-align: center;
}

.accordion-content .content-order-list .right {
	width: 90%;
}

.accordion-content .content-order-list .left,
.accordion-content .content-order-list .right .title {
	color: #268ac9;
	font-size: 16px;
	line-height: 22px;
	font-family: 'Poppins Bold';
}

.accordion-content .content-order-list .right .description,
.accordion-content .message {
	margin: 5px 0px;
	color: #3c3c3b;
	font-size: 16px;
	line-height: 25px;
	font-family: 'Poppins Regular';
}

.accordion-item.active .accordion-content {
	display: block;
}

@media screen and (max-width: 1000px) {
	.accordion-item {
		width: 100%;
	}
}
</style>

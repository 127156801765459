import { filter, includes } from 'lodash/fp'
import graphqlClient from '@/api/db'
import gql from 'graphql-tag'
import { logger } from '@/logger'
import {APP_ENV_SESSION, ignoredParkingTypes} from '@/config'
const markerImgBlue = require('../../assets/images/marker-blue.svg')
const markerImgRed = require('../../assets/images/marker-red.svg')
const markerImgYellow = require('../../assets/images/marker-yellow.svg')
const markerImgGreen = require('../../assets/images/marker-green.svg')
const markerImgOrange = require('../../assets/images/marker-orange.svg')
const addressMarker = require('../../assets/images/marker-home-purple.svg')

/* eslint-disable no-magic-numbers */
// Initial state
const initialState = () => ({
	loading: false,
	parkingsFetched: false,
	all: [],
	parkingTypes: [],
	legendOpen: false,
	details: {},
	detailsOpen: false,
	requestModalOpen: false,
	deleteModalOpen: false,
	parkingRequestId: '',
	parkingById:{}
})
const state = initialState()

export const parkingProps = `
	id
	name
	code
	gateNames
	insideGateNames
	installationDate
	latitude
	longitude
	parkingTypeName
	legacyAddress {
		nl
		fr
		en
	}
	capacityCargo
	capacityClassic
	parkingSpots {
	    amount,
	    name,
	    isCargo,
	    active
	}
	active
	comment {
		nl
		fr
		en
	}
	mobib
	airKey
	bePark
	badgeAccess
	isCycloparking
	ownerId
	bigParkingPrice
	sessionConfig {
		id
		parkingId
		perHourCost
		subscriptionFreeHours
	}
	images
`

// Getters
const getters = {
	getParking: (state) => (id) => {
		return state.all.find((parking) => parking.id === parseInt(id, 10))
	},
}

// Actions
const actions = {
	toggleDetails({ commit }, id) {
		commit('toggleDetails', id)
	},
	async getParkingById({ commit },parkingId) {
		commit('setLoading', true)
		try {
			const response = await graphqlClient.query({
				query: gql`
				query ParkingById($parkingId: Int) {
					parkingById(parkingId: $parkingId) {
							${parkingProps}
						}
					}
				`,
				variables: {
					parkingId
				},
			})
			const parkings = response.data.parkingById

			commit('setMyParkings', parkings)
		} catch (e) {
			logger.error('Problem retrieving your parkings', e)
		} finally {
			commit('setLoading', false)
		}
		},

	async getParkings({ commit }) {
		commit('setLoading', true)

		try {
			const response = await graphqlClient.query({
				query: gql`
					query Parkings {
						parkings {
							${parkingProps}
						}
					}
				`,
			})
			const legendParkingTypes = APP_ENV_SESSION ? [
				{
					icon: addressMarker,
					type: 'homeAddress',
					label: 'home',
					loggedIn: true,
				},
				{
					icon: markerImgRed,
					type: 'ParkingLaSession',
					label: 'laSession',
					loggedIn: true,
					isNew: true,
				},
			] : [
				{
					icon: addressMarker,
					type: 'homeAddress',
					label: 'home',
					loggedIn: true,
				},
			]
			/* eslint-disable-next-line */
			const parkings = response.data.parkings.map((p) => {
				const parking = Object.assign({}, p)
				const parkingTypeDetails = getParkingTypeDetails(parking.parkingTypeName)
				const parkingIcon = parkingTypeDetails.icon

				if (legendParkingTypes.findIndex((item) => item.type === parking.parkingTypeName) === -1) {
					if (!includes(parking.parkingTypeName, ignoredParkingTypes)) {
						legendParkingTypes.push({
							icon: parkingIcon,
							type: parking.parkingTypeName,
							label: parkingTypeDetails.label,
						})
					}
				}

				parking.parkingTypeLabel = parkingTypeDetails.label
				parking.detailsOpen = false
				parking.position = { lat: p.latitude, lng: p.longitude }
				parking.iconUrl = parkingIcon
				parking.address = p.legacyAddress
				delete parking.latitude
				delete parking.longitude
				delete parking.legacyAddress

				return parking
			})

			const duplicates = []

			parkings.forEach((p) => {
				parkings.forEach((parking, index) => {
					if (
						parking.position.lat === p.position.lat &&
						parking.position.lng === p.position.lng &&
						parking.id !== p.id
					) {
						const dupIndex = duplicates.findIndex(
							(dup) =>
								dup.position.lat === parking.position.lat &&
								dup.position.lng === parking.position.lng
						)

						if (dupIndex >= 0) {
							duplicates[dupIndex].indexes.push(index)
						} else {
							duplicates.push({ indexes: [index], position: parking.position })
						}
					}
				})
			})

			const indexes = duplicates.map((dup) => {
				const indexList = dup.indexes.filter((item, index) => dup.indexes.indexOf(item) === index)

				return indexList
			})
			const locationDiff = 0.0002
			const decimalPlaces = 6

			indexes.forEach((duplicatedPlaces) => {
				const amountCoppies = duplicatedPlaces.length

				duplicatedPlaces.forEach((index, i) => {
					const position = parkings[index].position

					parkings[index].line = true
					parkings[index].lineColor = getParkingTypeDetails(parkings[index].parkingTypeName).color
					parkings[index].lineTo = Object.assign({}, position)

					if (amountCoppies === 2) {
						position.lng = parseFloat(
							(Math.cos((30 * (4 * i + 1) * Math.PI) / 180) * locationDiff + position.lng).toFixed(
								decimalPlaces
							)
						)
						position.lat = parseFloat(
							(Math.sin((30 * (4 * i + 1) * Math.PI) / 180) * locationDiff + position.lat).toFixed(
								decimalPlaces
							)
						)
					} else {
						position.lng = parseFloat(
							(
								Math.cos(((360 / amountCoppies) * i * Math.PI) / 180) * locationDiff +
								position.lng
							).toFixed(decimalPlaces)
						)
						position.lat = parseFloat(
							(
								Math.sin(((360 / amountCoppies) * i * Math.PI) / 180) * locationDiff +
								position.lat
							).toFixed(decimalPlaces)
						)
					}
				})
			})

			commit('setParkings', parkings)
			commit('setParkingTypes', legendParkingTypes)
			commit('setParkingsFetchedStatus', true)
		} catch (e) {
			logger.error('Problem retrieving parkings', e)
		} finally {
			commit('setLoading', false)
		}
	},
	async getSessionParkings({ commit }) {
		commit('setLoading', true)

		try {
			const response = await graphqlClient.query({
				query: gql`
					query Parkings {
						parkingsHaveSessionConfig {
							${parkingProps}
						}
					}
				`,
			})
			const legendParkingTypes = APP_ENV_SESSION ? [
				{
					icon: addressMarker,
					type: 'homeAddress',
					label: 'home',
					loggedIn: true,
				},
				{
					icon: markerImgRed,
					type: 'ParkingLaSession',
					label: 'laSession',
					loggedIn: true,
					isNew: true,
				},
			] : [
				{
					icon: addressMarker,
					type: 'homeAddress',
					label: 'home',
					loggedIn: true,
				},
			]
			/* eslint-disable-next-line */
			const parkings = response.data.parkingsHaveSessionConfig.map((p) => {
				const parking = Object.assign({}, p)
				const parkingTypeDetails = getParkingTypeDetails(parking.parkingTypeName)
				const parkingIcon = parkingTypeDetails.icon

				if (legendParkingTypes.findIndex((item) => item.type === parking.parkingTypeName) === -1) {
					if (!includes(parking.parkingTypeName, ignoredParkingTypes)) {
						legendParkingTypes.push({
							icon: parkingIcon,
							type: parking.parkingTypeName,
							label: parkingTypeDetails.label,
						})
					}
				}

				parking.parkingTypeLabel = parkingTypeDetails.label
				parking.detailsOpen = false
				parking.position = { lat: p.latitude, lng: p.longitude }
				parking.iconUrl = parkingIcon
				parking.address = p.legacyAddress
				delete parking.latitude
				delete parking.longitude
				delete parking.legacyAddress

				return parking
			})

			const duplicates = []

			parkings.forEach((p) => {
				parkings.forEach((parking, index) => {
					if (
						parking.position.lat === p.position.lat &&
						parking.position.lng === p.position.lng &&
						parking.id !== p.id
					) {
						const dupIndex = duplicates.findIndex(
							(dup) =>
								dup.position.lat === parking.position.lat &&
								dup.position.lng === parking.position.lng
						)

						if (dupIndex >= 0) {
							duplicates[dupIndex].indexes.push(index)
						} else {
							duplicates.push({ indexes: [index], position: parking.position })
						}
					}
				})
			})

			const indexes = duplicates.map((dup) => {
				const indexList = dup.indexes.filter((item, index) => dup.indexes.indexOf(item) === index)

				return indexList
			})
			const locationDiff = 0.0002
			const decimalPlaces = 6

			indexes.forEach((duplicatedPlaces) => {
				const amountCoppies = duplicatedPlaces.length

				duplicatedPlaces.forEach((index, i) => {
					const position = parkings[index].position

					parkings[index].line = true
					parkings[index].lineColor = getParkingTypeDetails(parkings[index].parkingTypeName).color
					parkings[index].lineTo = Object.assign({}, position)

					if (amountCoppies === 2) {
						position.lng = parseFloat(
							(Math.cos((30 * (4 * i + 1) * Math.PI) / 180) * locationDiff + position.lng).toFixed(
								decimalPlaces
							)
						)
						position.lat = parseFloat(
							(Math.sin((30 * (4 * i + 1) * Math.PI) / 180) * locationDiff + position.lat).toFixed(
								decimalPlaces
							)
						)
					} else {
						position.lng = parseFloat(
							(
								Math.cos(((360 / amountCoppies) * i * Math.PI) / 180) * locationDiff +
								position.lng
							).toFixed(decimalPlaces)
						)
						position.lat = parseFloat(
							(
								Math.sin(((360 / amountCoppies) * i * Math.PI) / 180) * locationDiff +
								position.lat
							).toFixed(decimalPlaces)
						)
					}
				})
			})

			commit('setParkings', parkings)
			commit('setParkingTypes', legendParkingTypes)
			commit('setParkingsFetchedStatus', true)
		} catch (e) {
			logger.error('Problem retrieving parkings', e)
		} finally {
			commit('setLoading', false)
		}
	},
	toggleLegend({ commit }) {
		commit('toggleLegend')
	},
	setDetails({ commit }, payload) {
		// This hugly fix should disapear. Big100 should never have exist
		const parkingTypeName =
			payload.parkingTypeName === 'Big100' ? 'BigParking' : payload.parkingTypeName

		commit('setDetails', Object.assign({}, payload, { parkingTypeName }))
		commit('setDetailsOpen', true)
	},
	closeDetails({ commit }) {
		commit('setDetailsOpen', false)
	},
	tapMarker({ commit }, payload) {
		if (payload.detailsOpen) {
			commit('setDetails', payload)
			commit('setDetailsOpen', true)
		} else {
			commit('closeOpenDetails', payload.id)
			commit('toggleDetails', payload.id)
		}
	},
	toggleRequestModal({ commit }, payload = {}) {
		commit('toggleRequestModal')

		if (payload.addressId) {
			commit('setParkingRequestId', payload.addressId)
		}
	},
	deleteParkingRequestId({ commit }) {
		commit('setParkingRequestId', '')
	},
	toggleDeleteModal({ commit }) {
		commit('toggleDeleteModal')
	},
	closeRequestModal({ commit }) {
		commit('closeRequestModal')
	},
}

// Mutations
const mutations = {
	closeOpenDetails(id) {
		state.all.forEach((parking, index) => {
			if (parking.detailsOpen && id !== parking.id) {
				state.all[index].detailsOpen = false
			}
		})
	},
	toggleDetails(state, id) {
		const markerIndex = state.all.findIndex((parking) => parking.id === id)

		state.all[markerIndex].detailsOpen = !state.all[markerIndex].detailsOpen
	},
	setParkings(state, parkings) {
		state.all = filter((p) => Boolean(p.position.lat) && Boolean(p.position.lng), parkings)
	},
	setLoading(state, status) {
		state.loading = status
	},
	setParkingsFetchedStatus(state, status) {
		state.parkingsFetched = status
	},
	setParkingTypes(state, parkingTypes) {
		state.parkingTypes = parkingTypes
	},
	toggleLegend(state) {
		state.legendOpen = !state.legendOpen
	},
	setDetails(state, details) {
		state.details = details
	},
	setDetailsOpen(state, status) {
		state.detailsOpen = status
	},
	toggleRequestModal: (state) => {
		state.requestModalOpen = !state.requestModalOpen
	},
	toggleDeleteModal: (state) => {
		state.deleteModalOpen = !state.deleteModalOpen
	},
	closeRequestModal: (state) => {
		state.requestModalOpen = false
	},
	setParkingRequestId(state, id) {
		state.parkingRequestId = id
	},
	setMyParkings(state, parkings) {
		state.parkingById = parkings
	},
}

const getParkingTypeDetails = (type) => {
	switch (type) {
		case 'Box':
			return {
				icon: markerImgBlue,
				label: type.toLowerCase(),
				color: '#258AC9',
			}
		case 'BigParking':
			return {
				icon: markerImgRed,
				label: type.toLowerCase(),
				color: '#E75130',
			}
		case 'Big100':
			return {
				icon: markerImgRed,
				label: 'bigparking',
				color: '#E75130',
			}
		case 'NeighborhoodLocker':
			return {
				icon: markerImgYellow,
				label: type.toLowerCase(),
				color: '#FFD022',
			}
		case 'NeighborhoodLockerCargo':
			return {
				icon: markerImgYellow,
				label: type.toLowerCase(),
				color: '#FFD022',
			}
		case 'P+R':
			return {
				icon: markerImgGreen,
				label: type.toLowerCase(),
				color: '#019A3E',
			}
		case 'PrivateParkings':
			return {
				icon: markerImgOrange,
				label: type.toLowerCase(),
				color: '#EDA132',
			}
		case 'ParkingLaSession':
			return {
				icon: markerImgRed,
				label: 'laSession',
				isNew: true,
			}
		default:
			return {
				icon: markerImgBlue,
			}
	}
}

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations,
}

<template>
	<div :style="{ backgroundColor: backgroundColor }" class="wrapper">
		<div class="credit-card-logos">
			<!-- Visa -->
			<!-- <img src="../../assets/images/VisaCardLogo.png" class="YQ4gaf zr758c wA1Bge" height="31" width="56" alt="" data-csiid="15" data-atf="1"> -->
			<!-- Mastercard -->
			<!-- <img src="../../assets/images/MasterCardLogo.png" class="YQ4gaf zr758c wA1Bge" height="34" width="56" alt="" data-csiid="13" data-atf="1"> -->
			<!-- American Express -->
			<!-- <img  src="../../assets/images/ExpressCardLogo.png" class="YQ4gaf zr758c wA1Bge" height="56" width="56" alt="" data-csiid="14" data-atf="1"> -->
			<h2>{{ $t('sessionParking.payment.title') }}</h2>
		</div>
		<Loading v-if="isLoading || loading" />
		<form @submit.prevent="handleSubmit" class="form" id="mcForm">
			<div class="form-fields">
				<div class="row">
					<div class="form-group form-group--card-number">
						<label class="label" for="card-number">{{
							$t('sessionParking.payment.cardNumberTitle')
						}}</label>
						<div ref="cardNumber" class="mollie-input"></div>
						<div class="field-error" role="alert">{{ cardNumberError }}</div>
					</div>
				</div>

				<div class="roww">
					<div class="form-group form-group--expiry-date">
						<label class="label" for="expiry-date">{{
							$t('sessionParking.payment.expiryTitle')
						}}</label>
						<div ref="expiryDate" class="mollie-input"></div>
						<div class="field-error" role="alert">{{ expiryDateError }}</div>
					</div>

					<div
						class="form-group form-group--verification-code"
						style="position: relative; left: 10px"
					>
						<label class="label" for="verification-code">{{
							$t('sessionParking.payment.cvvTitle')
						}}</label>
						<div ref="verificationCode" class="mollie-input"></div>
						<div class="field-error" role="alert">{{ verificationCodeError }}</div>
					</div>
				</div>

				<div class="row">
					<div class="form-group form-group--card-holder">
						<label class="label" for="card-holder">{{
							$t('sessionParking.payment.cardHolderTtile')
						}}</label>
						<div ref="cardHolder" class="mollie-input" placeholder="Enter card holder's name"></div>
						<div class="field-error" role="alert">{{ cardHolderError }}</div>
					</div>
				</div>

				<!-- <span v-if="dataFromParent != null" style="display: inline-flex; vertical-align: middle">
					<input
						type="checkbox"
						id="enregistrerMode"
						name="modePaiement"
						value="enregistrer"
						v-model="isChecked"
					/>
					<label
						for="enregistrerMode"
						style="
							display: inline-flex;
							position: relative;
							left: 12px;
							bottom: -2px;
							color: #3c3c3b;
							font-size: 16px;
							font-family: Poppins Regular;
						"
						>{{ $t('sessionParking.payment.savePaymentTitle') }}</label
					>
				</span> -->

				<button
					v-if="dataFromParent == null"
					class="submit-button"
					type="submit"
					:disabled="submitButtonDisabled"
				>
					{{ $t('sessionParking.payment.ctaTitle') }}
				</button>
				<button
					v-else="dataFromParent != null"
					class="submit-button"
					type="submit"
					:disabled="submitButtonDisabled"
				>
					{{ $t('sessionParking.payment.ctaText') }}
				</button>
				<div class="field-error" role="alert">{{ formError }}</div>
				<div class="field-error" role="alert">{{ paymentStatus }}</div>
			</div>
		</form>
	</div>
</template>

<script>
import { mapState, mapGetters, mapActions, mapMutations } from 'vuex'
import Loading from '@/components/Loading.vue'
import io from 'socket.io-client'
import { config } from '@/config'
export default {
	name: 'MollieStep2',
	components: {
		Loading,
	},
	props: {
		dataFromParent: {
			type: String,
			default: null,
		},
		backgroundColor: String,
	},
	data() {
		return {
			mollie: null,
			cardHolderError: '',
			cardNumberError: '',
			expiryDateError: '',
			verificationCodeError: '',
			formError: '',
			submitButtonDisabled: false,
			paymentStatus: '',
			modalWindow: null,
			timeoutId: null,
			cardHolderComponent: null,
			cardNumberComponent: null,
			expiryDateComponent: null,
			verificationCodeComponent: null,
			addpaymentMethod: this.dataFromParent,
			isChecked: false,
		}
	},
	computed: {
		voucherName: {
			get() {
				return this.$store.state.subscription.voucher.name
			},
			set(name) {
				this.setVoucherName(name)
			},
		},
		...mapState({
			currentUser: (state) => state.profile.currentUser,
			subscription: (state) => state.subscription.current,
			isLoading: (state) => state.subscription.isLoading,
			loading: (state) => state.profile.loading,
		}),
		priceParking() {
			return this.subscription.subscriptionType.price
		},
	},
	mounted() {
		// initalize the mollie card
		this.initializeMollie()

		// Connect to the Socket.IO server
		// this.socket = io(config.apiUrl, {query: { 'userId':  this.currentUser.id}}) // Replace with your server URL
		// // Handle events from the server for first payment
		// this.socket.on('paymentStatus', ({ userId, id, status, message }) => {
		// 	this.handleSocketEventPaymentStatus({ userId, id, status, message })
		// })

		const urlParams = new URLSearchParams(window.location.search);
		const paymentId = urlParams.get('paymentId');
		const subscriptionId = urlParams.get('subId');
		if (paymentId) {
			let noOfTimePaymentEventCalled = 0
			this.checkPaymentTimer = setInterval(async () => {
				const paymentResponse = await this.getPayentDetail({paymentId, subscriptionId})
				if(paymentResponse && paymentResponse.status != 'open' && noOfTimePaymentEventCalled < 1){
					clearInterval(this.checkPaymentTimer)
					noOfTimePaymentEventCalled++
					const userId = paymentResponse.metadata.userId
					const id = paymentResponse.id
					const status = paymentResponse.status
					const message = paymentResponse.details.failureMessage || 
					console.log({ userId, id, status, message })
					this.handleSocketEventPaymentStatus({ userId, id, status, message })					
				}
			}, 3000)
		}

		// Handle event from the server for Subscription first charge
		// this.socket.on('paymentFinalStatus', ({ status }) => {
		// 	this.closeModal()
		// 	if (status === 'paid') {
		// 		this.setLoading(false)
		// 		this.sendDataToParent()
		// 	} else {
		// 		this.paymentStatus = status + ':' + 'Failed please retry again from Profile'
		// 	}
		// })

		// // Example: handling connection error
		// this.socket.on('connect_error', (error) => {
		// 	console.error('Connection error:', error)
		// })
	},
	methods: {
		...mapActions({
			completeSubscription: 'subscription/completeSubscription',
			sessionStart: 'session/sessionStart',
			addNewPaymentMethod: 'profile/addNewPaymentMethod',
			updateCurrentSubscriptioin: 'subscription/updateCurrentSubscriptioin',
			getPayentDetail: 'payment/getPayentDetail',
		}),
		...mapMutations({
			setLoading: 'subscription/setLoading',
			sessionSetLoading: 'session/setLoading',
		}),
		initializeMollie() {
			this.mollie = Mollie(config.molliePublicKey, { locale: 'en_US', testmode: true })

			const options = {
				styles: {
					base: {
						fontSize: '15px',
						color: '#162941',
					},
				},
			}
			// Customize placeholder text
			// initialize cardHolderComponent
			this.cardHolderComponent = this.mollie.createComponent('cardHolder', options)
			this.createMollieComponent(
				this.cardHolderComponent,
				'cardHolder',
				this.$refs.cardHolder,
				'cardHolderError'
			)

			// initialize cardNumberComponent
			this.cardNumberComponent = this.mollie.createComponent('cardNumber', options)
			this.createMollieComponent(
				this.cardNumberComponent,
				'cardNumber',
				this.$refs.cardNumber,
				'cardNumberError'
			)

			// initialize expiryDateComponent
			this.expiryDateComponent = this.mollie.createComponent('expiryDate', options)
			this.createMollieComponent(
				this.expiryDateComponent,
				'expiryDate',
				this.$refs.expiryDate,
				'expiryDateError'
			)

			// initialize verificationCodeComponent
			this.verificationCodeComponent = this.mollie.createComponent('verificationCode', options)
			this.createMollieComponent(
				this.verificationCodeComponent,
				'verificationCode',
				this.$refs.verificationCode,
				'verificationCodeError'
			)
			// var hiddenInput = document.querySelector('iframe[name="cardNumber-input"]')
			// Set the background image
			// Set the background images
			// hiddenInput.style.backgroundImage =
			// 	"url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADgAAAAfCAMAAACF8f6iAAAAdVBMVEX///8UNMsAJMkAKspca9YALMoAJskAD8cIL8oAIMgAHcioreYAFMextumiqOUAAMaMlN9GV9G+wuxLXNLv8Pp7hdxlctf6+/7Cxu3f4fbm6PiVnOLO0fHV1/PZ3PSco+RWZtU5TdCBit0tRM5vetkfOswnP839FUl8AAAB00lEQVQ4je1TW4KDIAwkIIKIj/q2rda27t7/iJsE63KE/dj5aIlJJiQThPjHn8KjbbcEkQfzjkY7ihZ/ZzS7R+uMf/YLO+chSbbikzlOQ6W1ria2EqnlIGajtSFWk1oAsL5kp1RayySqelUAqqZT5wH8IgoJ9iLEDS0rM6n8Ss4iQxJQ8X1rBbbli6dgn0L0ioi6CkBfiql4GY56UnXw1yjx7vADHdCRYRNvC1nDFexv0Oopjxy/GH1gmpAgPc2HxLD7GbQrJJVHTx+0XEJsGtI6XEAjDZXwfRdCsH27jebo6RQlhfTGTfmOLT3g10tKwzCPTwhyf2GFOHFxSCduR8KgQbIAiad5uI3OOHk30n39HGc6mrNlLQT9u9Db9E0SONyOxoHqBQklizgxwSI5qvcmWbG3ajwcJVJSWxcLab6uNJ0+TsQkOLQQTRYIAlBTlHamQaXOYVjkQ8xBJElnXAdibXhdSHa1U/qJ8zYMzevEGuGtqI/WfO2vXWHDZiFlrSHIs/8DO1F61swATw5DtCI+/xJlWF4RplPGiYWR0vHmL5WUuJzXKsPHoJWj6lkmTXh4c3WEfdCVeV6yQiueaEZdU+/JXtNzu5Lz2CA85uIffws/lusXjWNFJpAAAAAASUVORK5CYII='), url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAABoklEQVR4Ae1WJVQEURT9uLsN/MFJpM14L1ii4E4/NBwa7vp7oqftiZ5o6Bx8XYa96PrO24TMPefWf+/T/1gk6NCh45bJLd5kYXAvKrItQm755IvgBhYNLplUoTAubhm/91ANpHzqbQZi1mP51HLCVX9aT/i9RXBhEVKFxoj5KkS0UMngRo8IqGqhVfDVSOJGreKgyZCp2pdy8LhmIhssGJByivhzTY6q9jCQbAKZCGw0gvhdugThbw7EqtYdiZgJuSXq1FvrUyHsQ+dkKh6m0PjV8RRxJbEYgkFpPSimmcBk3DDeSzHwWJYX0oBtJZ9kwCRK2lD/GWrnhzLgmM6kZeBYnvl9Bl5qs0IbmMsiGyCP4IOUH7oHNgrpo3jPKrLxMIXurtgAcfdIHEkcf0TUWxB9ELr+5JX8tQvuKbvA1Rn3Hf1EAjl6pB/fN/sEdR/c5xaiFFjD5Npj/nErfBmI1sSDXEhvvGPey8IAJgyex880GLhXWEkbokFKNaT9jHQh4XE0p7cZhfFzXEPIFKbH+xRDZB9X0bm3KJoNJlkI6NCh4xWIX+K8h1K4IAAAAABJRU5ErkJggg==')"
			// hiddenInput.style.backgroundRepeat = 'no-repeat, no-repeat'
			// hiddenInput.style.backgroundPosition = 'right 36px center, right 0px center'
		},
		createMollieComponent(component, type, element, errorField) {
			component.mount(element)

			component.addEventListener('change', (event) => {
				this.toggleFieldClass({ elementId: type, toggleClassesObject: event, errorField })

				if (event.error && event.touched) {
					this[errorField] = event.error
				} else {
					this[errorField] = ''
				}
			})
		},
		toggleFieldClass(elementClassObj) {
			const element = this.$refs[elementClassObj.elementId]

			Object.keys(elementClassObj.toggleClassesObject).forEach((key) => {
				if (typeof elementClassObj.toggleClassesObject[key] !== 'boolean') {
					return
				}

				if (elementClassObj.toggleClassesObject[key]) {
					element.parentNode.classList.add('is-' + key)
				} else {
					element.parentNode.classList.remove('is-' + key)
				}
			})
		},
		async handleSubmit() {
			this.disableForm()

			this.formError = ''

			var { token, error } = await this.mollie.createToken()
			if (error) {
				this.enableForm()
				this.formError = error?.message || ''
				return
			}
			if (this.addpaymentMethod == null) {
				if (this.subscription.id) {
					const result = await this.completeSubscription(token)

					if (result[0]) {
						const paymentIntentSecret = this.subscription.scaPaymentIntentSecret

						if (paymentIntentSecret) {
							return this.validateStripeSca(paymentIntentSecret)
						} else {
							window.location.href = result[1]
							return true
						}
					} else {
						// this.flash(this.alert.message, this.alert.type)
						logger.debug('Your subscription could not be confirmed, check your input')

						return true
					}
				} else {
					return true
				}
			} else if (this.addpaymentMethod == 'session') {
				const parkingId = this.$route.query.parkingId
				token = token + 'ID=' + parkingId
				const result = await this.addNewPaymentMethod(token)
				window.location.href = result.url
			} else if (this.addpaymentMethod == 'sub-addpayment-method') {
				const result = await this.addNewPaymentMethod(token)
				window.location.href = result.url
			} else if (this.addpaymentMethod == 'session-addpayment-method') {
				// add new payment method for sesssion here
			}
		},
		disableForm() {
			this.submitButtonDisabled = true
		},
		enableForm() {
			this.submitButtonDisabled = false
		},
		closeModal() {
			// Check if the modal window reference is defined and not closed before attempting to close it
			if (this.modalWindow && !this.modalWindow.closed) {
				this.modalWindow.close()
				this.modalWindow = null // Set to null after closing
				this.enableForm()
			}
		},
		sendDataToParent() {
			const data = 'Data from child'
			this.$emit('childEvent', data)
		},
		handleSocketEventPaymentStatus({ userId, id, status, message }) {
			if (!this.currentUser?.id) {
				setTimeout(() => {
					this.handleSocketEventPaymentStatus({ userId, id, status, message })
				}, 1000)
			}
			console.log(
				'socket.on->paymentStatus| handling ',
				this.currentUser.id == userId,
				id,
				status,
				message,
				this.currentUser.id,
				userId
			)
			if (userId != this.currentUser.id) {
				return false
			}
			if (id == 'session') {
				// for session
				this.sessionSetLoading(false)
				if (status === 'paid') {
					this.$emit('received-token', message)
				} else {
					this.paymentStatus = status + ':' + message
					if (this.timeoutId) {
						clearTimeout(this.timeoutId)
					}
					this.timeoutId = setTimeout(() => {
						this.paymentStatus = ''
					}, 5000)
				}
			} else {
				// for subscription
				this.setLoading(false)
				if (status === 'paid') {
					this.sendDataToParent()
				} else {
					this.setLoading(false)
					this.subscription.status = 1
					this.subscription.spotNumber = null
					this.subscription.paymentStatus = '0'
					this.updateCurrentSubscriptioin(this.subscription)
					this.paymentStatus = status + ':' + message
					if (this.timeoutId) {
						clearTimeout(this.timeoutId)
					}
					this.timeoutId = setTimeout(() => {
						this.paymentStatus = ''
					}, 5000)
				}
			}
		},
	},
	beforeDestroy() {
		// Disconnect from the Socket.IO server when the component is destroyed
		this.checkPaymentTimer && clearInterval(this.checkPaymentTimer)
		if (this.socket) {
			this.socket.disconnect()
		}
		// Unmount and clear each Mollie.js component
		if (this.cardHolderComponent) {
			this.cardHolderComponent.unmount()
			this.cardHolderComponent = null
		}
		if (this.cardNumberComponent) {
			this.cardNumberComponent.unmount()
			this.cardNumberComponent = null
		}
		if (this.expiryDateComponent) {
			this.expiryDateComponent.unmount()
			this.expiryDateComponent = null
		}
		if (this.verificationCodeComponent) {
			this.verificationCodeComponent.unmount()
			this.verificationCodeComponent = null
		}
		// Clear the timeout before it executes
		if (this.timeoutId) {
			clearTimeout(this.timeoutId)
			this.timeoutId = null
		}
	},
}
</script>

<style scoped>
* {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
}

body {
	font-family: 'Arial', sans-serif;
	background-color: #f5f5f5;
}

.wrapper {
	padding: 20px;
	max-width: 100%;
	margin: 20px auto auto auto;
	border-radius: 5px;
}

.form {
	max-width: 500px;
	margin: 0 auto;
}

.roww {
	display: flex;
	flex-wrap: wrap;
	margin-bottom: 10px;
}

.row {
	margin-bottom: 10px;
}

.form-fields {
	/* display: flex; */
	/* flex-wrap: wrap;
  margin-bottom: 20px; */
}

.form-group {
	flex: 1;
	margin-right: 10px;
	width: 100%;
}

.mollie-input {
	width: 100% !important;
	padding: 15px 15px;
	border: 1px solid #ffe175;
	border-radius: 4px;
	transition: border-color 0.3s;
	width: calc(100% - 10px);
	background-color: white;
}

.mollie-input::placeholder {
	color: #252323;
}

.label {
	font-weight: 500;
	margin-bottom: 5px;
	color: #3c3c3b;
	font-size: 12px;
	font-weight: bold;
	font-family: Poppins Bold;
}

.field-error {
	color: #ff4d4d;
	margin-top: 5px;
	font-size: 14px;
}

input:focus {
	outline: none;
	border-color: #4caf50;
}

.submit-button {
	width: 100%;
	background-color: #e75131;
	margin-top: 20px;
	cursor: pointer;
	font-family: Poppins Bold;
	color: #fff;
	font-size: 16px;
	padding: 18px;
	text-align: center;
	border: none;
	border-radius: 4px;
	line-height: 25px;
	text-transform: none;
	display: inline-block;

	box-shadow: -5px 5px 0 0 rgba(0, 0, 0, 0.160784);
}

.submit-button:disabled {
	background-color: #ccc;
	cursor: not-allowed;
}

.submit-button:hover:enabled {
	background-color: #e84421;
}

.credit-card-logos {
	text-align: center;
	margin-bottom: 20px;
	color: #3c3c3b;
	font-weight: bold;
	font-size: 18px;
	font-family: 'Poppins Bold';
}

span {
	display: flex;
	align-items: center;
}

@media screen and (max-width: 768px) {
	.mollie {
		width: 80%;
		margin-left: auto;
		margin-right: auto;
	}
	.roww {
		display: flex;
		flex-wrap: wrap;
		margin-bottom: 10px;
	}
}
</style>

<template>
	<div class="main">
		<Loading v-if="isLoading" />
		<h1 class="h1">{{ $t('sessionParking.confirmation.title') }}</h1>

		<p style="text-align: center">{{ $t('sessionParking.confirmation.info') }}</p>

		<div class="velos-container">
			<p>{{ $t('sessionParking.confirmation.numOfBikesText') }}</p>
			<img
				src="../../assets//images/session/icon_bike_black.svg"
				alt="Add Circle Icon"
				class="icon-svg"
			/>

			<img
				@click="bikeDecrement"
				src="../../assets//images/session/icon_counter_minus.svg"
				alt="Add Circle Icon"
				class="icon-svg"
				style="cursor: pointer"
			/>

			<p>{{ bikes }}</p>

			<img
				@click="bikeIncrement"
				src="../../assets//images/session/icon_counter_plus.svg"
				alt="Add Circle Icon"
				class="icon-svg"
				style="cursor: pointer"
			/>
		</div>

		<div
			v-if="parking.sessionConfig"
			style="
				display: flex;
				flex-direction: row;
				align-items: center;
				justify-content: space-between;
				margin: 0px;
			"
		>
			<img
				src="../../assets//images/session/icon_location_bigparking.svg"
				alt="Add Circle Icon"
				class="icon-svg"
			/>
			<h3 style="color: #278ac9">{{ parking.code }} - {{ parking.name }}</h3>
			<p
				@click="$router.push(`/parkings?typeFilter=ParkingLaSession`)"
				style="text-decoration: underline; color: red; cursor: pointer"
			>
				{{ $t('general.modify') }}
			</p>
		</div>

		<span style="font-size: small; font-weight: bold; text-align: center">
			<img
				v-if="
					parking &&
					parking.legacyAddress[activeLanguage.value] &&
					parking.legacyAddress[activeLanguage.value].includes('(M)')
				"
				class="how-it-works-address-wrapper-m"
				src="../../assets/images/session/Métrro.svg"
			/>
			{{ replaceSubstring(parking.legacyAddress[activeLanguage.value]) }}
		</span>

		<div
			style="
				display: flex;
				flex-direction: row;
				align-items: center;
				justify-content: space-between;
			"
		>
			<h2>{{ $t('sessionParking.confirmation.paymentModifyText') }}</h2>
			<p @click="handleClick" style="text-decoration: underline; color: red; cursor: pointer">
				{{ $t('general.modify') }}
			</p>
		</div>

		<div
			class="card-details"
			style="display: flex; flex-direction: row; align-items: center"
			v-if="mandate && mandate.details"
		>
			<div>
				<img
					:src="getIconSource(mandate.details.cardLabel)"
					alt="Add Circle Icon"
					class="icon-svg"
				/>
			</div>
			<p>**** **** **** {{ mandate.details.cardNumber }}
				<br/> <span class="expires">Expires {{ mandate.details.cardExpiryDate }}</span>
			</p>
		</div>

		<hr style="border: 1px solid black; width: 100%" />

		<div
			style="
				display: flex;
				flex-direction: row;
				align-items: center;
				justify-content: space-between;
				z-index: 1;
			"
		>
			<div style="display: flex; flex-direction: row; align-items: center" @click="totalClick">
				<h2>{{ $t('sessionParking.confirmation.paymentTotalText') }}</h2>
				<img
					style="cursor: pointer"
					src="../../assets//images/session/icon_info_small.svg"
					alt="Add Circle Icon"
					class="icon-svg"
				/>
			</div>

			<div style="display: flex; flex-direction: row; align-items: center">
				<img
					src="../../assets//images/session/icon_euro.svg"
					alt="Add Circle Icon"
					class="icon-svg"
				/>
				<h1>{{ parking.sessionConfig.perHourCost * bikes }}</h1>
			</div>
		</div>

		<div
			style="
				display: flex;
				flex-direction: row;
				align-items: center;
				justify-content: space-between;
				position: relative;
				bottom: 42px;
			"
		>
			<h2>{{ $t('sessionParking.confirmation.paymentTotalText2') }}</h2>
			<p>/{{ $t('general.hour') }}</p>
		</div>

		<div style="display: flex; justify-content: center">
			<p
				style="
					text-align: center;
					background-color: #fcf3d2;
					height: 1rem;
					align-items: center;
					width: 100%;
					padding: 15px;
					border-radius: 10px;
				"
			>
				{{
					$t('sessionParking.confirmation.disclaimerText') +
					' ' +
					parking.sessionConfig.subscriptionFreeHours +
					' ' +
					$t('sessionParking.confirmation.disclaimerText2')
				}}
			</p>
		</div>

		<div style="display: flex; justify-content: center">
			<button @click="startSession">{{ $t('sessionParking.confirmation.ctaText') }}</button>
		</div>
		<SessionModal :showModal="showModal1" @close="closeModal1" @confirm="confirmAction1">
			<template v-slot:title>
				<h1>{{ $t('sessionParking.modal.infoPayment.modalTitle') }}</h1>
			</template>
			<template v-slot:content>
				<p>
					{{ $t('sessionParking.modal.infoPayment.modalDescription') }}
				</p>
			</template>
			<template v-slot:confirmButton>
				<button @click="confirmAction1">
					{{ $t('sessionParking.modal.infoPayment.ModalConfirm') }}
				</button>
			</template>
		</SessionModal>
		<SessionModal :showModal="showModal" @close="closeModal" @confirm="confirmAction">
			<template v-slot:title>
				<h1>{{ $t('sessionParking.modal.startSession.modalTitle') }}</h1>
			</template>
			<template v-slot:content>
				<p>
					{{ $t('sessionParking.modal.startSession.modalDescription') }}
				</p>
			</template>
			<template v-slot:confirmButton>
				<button @click="confirmAction">
					{{ $t('sessionParking.modal.startSession.ModalConfirm') }}
				</button>
			</template>
		</SessionModal>
	</div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import Loading from '@/components/Loading.vue'
import SessionModal from '@/components/SessionModal.vue'
import { BOURSE } from '../../store/modules/session'
export default {
	name: 'step2',
	components: {
		SessionModal,
		Loading,
	},
	props: {
		token: {
			type: String,
			default: null,
		},
		parking: {
			type: Object,
			default: () => ({}),
		},
		backgroundColor: String,
		mandate: {
			type: Object,
			default: () => ({}),
		},
	},
	created() {
		this.parkingId = this.$route.query.parkingId
	},
	data() {
		return {
			showModal: false,
			showModal1: false,
			bikes: 1,
			parkingId: null,
		}
	},
	computed: {
		...mapState({
			currentUser: (state) => state.profile.currentUser,
			isLoading: (state) => state.session.isLoading,
			activeLanguage: (state) => state.language,
			errorMessage: (state) => state.session.error,
		}),
	},

	methods: {
		...mapActions({
			startNewSession: 'session/startNewSession',
		}),
		replaceSubstring(address) {
			if (address) {
				return address.replace('(M)', '')
			}
			return ''
		},
		...mapActions('alert', ['error']),
		async sessionStart() {
			try {
				let props = {}
				const currentTime = new Date()
				const hours = currentTime.getHours()
				const minutes = currentTime.getMinutes()
				if (
					this.parkingId == BOURSE.ID &&
					hours >= BOURSE.NIGHT.START &&
					hours <= BOURSE.NIGHT.END
				) {
					props = {
						parkingId: parseInt(this.parkingId) || 2,
						paymentMethod: this.token,
						userId: this.currentUser.id,
						bikes: this.bikes,
						gateType: 'main-door',
					}
				} else {
					props = {
						parkingId: parseInt(this.parkingId) || 2,
						paymentMethod: this.token,
						userId: this.currentUser.id,
						bikes: this.bikes,
					}
				}

				const response = await this.startNewSession(props)

				if (response) {
					if (props.gateType) {
						this.$router.push('/main-door-qr')
					} else {
						this.$router.push('/session-start-qr')
					}
				} else {
					this.error(this.errorMessage)
				}
			} catch (error) {
				console.error('Error starting session:', error)
			}
		},

		startSession() {
			this.showModal = true
		},
		handleClick() {
			this.$emit('previous-step', 1)
		},
		closeModal() {
			this.showModal = false
		},
		closeModal1() {
			this.showModal1 = false
		},
		confirmAction() {
			this.showModal = false
			this.sessionStart()
		},
		confirmAction1() {
			this.showModal1 = false
		},
		bikeIncrement() {
			if (this.bikes < 5) {
				this.bikes++
			}
			this.storeBikes(this.bikes)
		},

		bikeDecrement() {
			if (this.bikes > 1) {
				this.bikes--
			}
			this.storeBikes(this.bikes)
		},
		totalClick() {
			this.showModal1 = true
		},
		getIconSource(cardLabel) {
			// Return appropriate icon source based on card label
			return require(`@/assets/images/session/icon_payment_${cardLabel.toLowerCase()}.svg`)
		},
		storeBikes(bikes) {
			localStorage.setItem('sessionBikes', bikes)
		},
	},
	async mounted() {
		const sessionBikes = localStorage.getItem('sessionBikes')

		if (sessionBikes) {
			this.bikes = parseInt(sessionBikes)
			this.storeBikes(sessionBikes)
		} else {
			this.storeBikes(this.bikes)
		}

		console.log('parking', this.parking)
	},
}
</script>

<style lang="scss" scoped>
@import '../../assets/scss/pages/session/index';

.left-arrow::before {
	content: '';
	display: inline-block;
	width: 0;
	height: 0;
	border-top: 5px solid transparent;
	border-bottom: 5px solid transparent;
	border-right: 5px solid black;
	margin-right: 5px;
}

.material-symbols-outlined {
	font-family: 'YourMaterialFontFamily';
	font-variation-settings: 'FILL' 0, 'wght' 400, 'GRAD' 0, 'opsz' 24;
}

.main {
	display: flex;
	flex-direction: column;
	/* border: 0.5px solid rgb(224, 215, 215); */
	padding: 15px;
	width: 25%;
	margin-left: auto;
	margin-right: auto;
	/* box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); */

	@media (max-width: 768px) {
		width: 20rem;
	}
}

.h1 {
	color: #1b388a;
	text-align: center;
}

.velos-container {
	display: flex;

	flex-direction: row;
	background-color: #ffd023;
	border-radius: 10px;
	height: 1.8rem;

	padding: 10px;
	align-items: center;
	justify-content: space-between;
}

button {
	padding: 10px 20px;
	background-color: #e75131; /* Green */
	border: none;
	color: white;
	text-align: center;
	text-decoration: none;
	display: inline-block;
	justify-content: center;
	font-size: 16px;
	margin: 4px 2px;
	width: 100%;
	cursor: pointer;
	border-radius: 5px;
	box-shadow: -5px 5px 0px 0px rgba(182, 175, 175, 0.5); /* Black shadow with x-offset -5px, y-offset 5px, and blur radius 0px */
}

.card-details {
	text-align: end;

	.expires {
		font-size: 12px;
	}
}
</style>
